import { Flex, Image, Text } from "@chakra-ui/react";
import yellowCircleIcon from "../../../assets/image/body/circle.png";
import styles from "./Tokenomics.module.scss";

export default function Tokenomics() {
  return (
    <Flex flexDirection={'column'} alignContent={'center'} id='tokenomics'>
      <Flex className={styles.title} >Tokenomics</Flex>
      <Flex className={styles.detailWrapper}>
        <Image src={yellowCircleIcon} alt="logo" className={styles.icon} />
        <Flex className={styles.text}>
            <Text>10 billion $MOLE Token Supply</Text>
            <Text>BUY/SELL tax:0%</Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
