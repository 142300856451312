import { Flex, Image } from "@chakra-ui/react";
import logoIcon from "../../assets/image/logo.png";
import styles from "./Header.module.scss";
import { HashLink as Link } from "react-router-hash-link";

const links = [
  {
    name: "HOME",
    link: "/#home",
  },
  {
    name: "HOW TO BUY",
    link: "/#how-to-buy",
  },
  {
    name: "TOKENOMICS",
    link: "/#tokenomics",
  },
  {
    name: "ROADMAP",
    link: "/#roadmap",
  },
];
export default function Header() {
  return (
    <Flex className={styles.container}>
      <Image src={logoIcon} alt="logo" className={styles.logo} />

      <Flex className={styles.linkGroup}>
        {links.map((link) => (
          <Flex key={link.name} className={styles.link}>
            <Link to={link.link}>{link.name}</Link>
          </Flex>
        ))}
      </Flex>

      <CButton />
    </Flex>
  );
}

const CButton = () => {
  return (
    <Flex
      px={4}
      py={1}
      border="1px solid white"
      borderRadius="full"
      userSelect={"none"}
      cursor={"pointer"}
    >
      Buy Presale
    </Flex>
  );
};
