import { Flex, Image } from "@chakra-ui/react";
import heartIcon from "../../../assets/image/body/heart.png";
import styles from "./Heart.module.scss";
export default function Heart() {
  return (
    <Flex className={styles.container}>
      <Flex>
        <Image className={styles.icon} src={heartIcon} alt="logo" />
      </Flex>
    </Flex>
  );
}
