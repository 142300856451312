import { Box, Flex, Image } from "@chakra-ui/react";
import styles from "./HowToBuy.module.scss";

const steps: { title: string; description: React.ReactNode }[] = [
  {
    title: "MAKE A WALLET",
    description:
      "download metamask or your wallet of choice from the app store or google play store for free. FOR DESKTOP USERS, DOWNLOAD THE GOOGLE CHROME",
  },
  {
    title: "BUY BNB",
    description:
      "connect to pancakeswap. Go to pancakeswap.finance in google chrome or on the browser inside your Metamask app. Connect your wallet. Paste the $MOLE token address into pancakeswap, select $MOLE COIN, and confirm. When Metamask prompts you for a wallet signature, sign.",
  },
  {
    title: "ADD BNB TO YOUR WALLET",
    description:
      "SWAP BNB FOR $MOLE. WE HAVE ZERO TAXES, SO YOU DON'T NEED TO WORRY ABOUT BUYING WITH SPECIFIC SLIPPAGE, ALTHOUGH YOU MAY NEED TO USE SLIPPAGE DURING TIMES OF MARKET VOLATILITY.",
  },
];

export default function HowToBuy() {
  return (
    <Flex className={styles.container} flexDirection={"column"} id='how-to-buy'>
      <Flex className={styles.title}>HOW TO BUY</Flex>
      {steps.map((step, index) => (
        <Box key={step.title} className={styles.step}>
          <Flex className={styles.stepNumber} flexDirection={"column"}>
            <Flex>STEP</Flex>
            {index + 1}
          </Flex>
          <Flex className={styles.stepContent} flexDirection={"column"}>
            <Flex className={styles.stepTitle}>{step.title}</Flex>
            <Flex className={styles.stepDescription} textAlign="start">
              {step.description}
            </Flex>
          </Flex>
        </Box>
      ))}
    </Flex>
  );
}
