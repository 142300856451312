import { Flex, Image, Text } from "@chakra-ui/react";
import logoIcon from "../../assets/image/logo.png";
import styles from "./Footer.module.scss";
export default function Footer() {
  return (
    <Flex className={styles.container}>
      <Image className={styles.icon} src={logoIcon} alt="logo" />
      <Text className={styles.text}>Copyright 2024 | All Rights Reserved</Text>
    </Flex>
  );
}
