import { Flex, Image } from "@chakra-ui/react";
import bodyShapeIcon from "../../assets/image/body/body-shape.png";
import sheidIcon from "../../assets/image/body/shield.png";
import styles from "./Body.module.scss";
import HowToBuy from "./HowToBuy/HowToBuy";
import Tokenomics from "./Tokenomics/Tokenomics";
import Roadmap from "./Roadmap/Roadmap";
import Heart from "./Heart/Heart";

export default function Body() {
  return (
    <Flex className={styles.container} flexDirection={'column'}>
      <Image src={bodyShapeIcon} alt="logo" className={styles.bodyShapeIcon} />
      <Image src={sheidIcon} alt="logo" className={styles.sheidIcon} />

      <HowToBuy />

      <Tokenomics />
      <Roadmap />
      <Heart />
    </Flex>
  );
}
