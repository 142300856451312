import React from "react";
import { Flex } from "@chakra-ui/react";
import Header from "./components/Header/Header";
import styles from "./App.module.scss";
import Body from "./components/Body/Body";
import Banner from "./components/Banner/Banner";
import Footer from "./components/Footer/Footer";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <Flex
              width="100%"
              flexDirection={"column"}
              alignItems={"center"}
              overflowX={"hidden"}
              className={styles.App}
            >
              <Header />
              <Banner />
              <Body />
              <Footer />
            </Flex>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
