import { Flex, Image } from "@chakra-ui/react";
import roadmapIcon from "../../../assets/image/body/roadmap.png";
import styles from "./Roadmap.module.scss";
export default function Roadmap() {
  return (
    <Flex className={styles.container} id="roadmap">
      <Flex className={styles.title}>Roadmap</Flex>
      <Flex>
        <Image src={roadmapIcon} alt="logo" />
      </Flex>
    </Flex>
  );
}
